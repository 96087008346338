import React from "react";
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';


export const Icon = ({value,size,hover,color,color_variant,colorClass,cssClasses,onClick}) => {

    if(!colorClass && !hover){

        return (

            <img className={`icon-${size}  icon-${color?color:''}-${color_variant?color_variant:''} ${cssClasses}`} src={process.env.REACT_APP_URL+"img/icons/"+value.toLowerCase().replaceAll(" ","-")+".svg"} onClick={onClick}/>
        );

    }else{

        let size_def=16;

        if(size=="medium"){
            size_def=24;
        }
        if(size=="large"){
            size_def=32;
        }
        if(size=="extralarge"){
            size_def=40; 
        }
        return (
            <span className={`${hover?'hover-inline-svg':''} inline-svg-${size} inline-svg-${color?color:''}-${color_variant?color_variant:''} `}>
                <SVG
                    src={process.env.REACT_APP_URL+"img/icons/"+(value && value.toLowerCase().replaceAll(" ","-"))+".svg"}
                    width={size_def}
                    className={colorClass}
                    height={size_def}
                    onClick={onClick}
                />
            </span>
        )
    }
    // if(hover){
    //
    //
    //
    // }

};

Icon.propTypes = {
    value:PropTypes.string,
    size: PropTypes.string,

};
Icon.defaultProps = {
    value: "add",
    size:"medium",

};