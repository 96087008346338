import React, { useState, useRef, useEffect } from "react";
import { Text } from '../Text/Text';
import { Tooltip } from '../Tooltip/Tooltip';
import { RatingScaleIcon } from "../RatingScaleIcon/RatingScaleIcon";
import { Icon } from "../Icon/Icon";
import exportAsImage from "../../../../lib/exportAsImage";

export const RatingScaleChart = ({ test, data, question, predictions }) => {


    const ref = useRef();
    const tipref = useRef();
    const exportPngRef = useRef();



    var options = [];

    for(var i=0; i<question.scale;i++){
        options.push(i+1);
    }



    const [tooltip, showTooltip] = useState({
        tooltipLeft: 0,
        tooltipTop: 0,
        tooltipData: "",
        open: false,
        show: false
    });

    const handlePointerMove = (tooltipdata) => {


        return (
            (event) => {


                let tipPosition = "bottom";

                var tool_width = tipref && tipref.current ? tipref.current.clientWidth : 150;


                var tool_height = tipref && tipref.current ? tipref.current.clientHeight + 12 : 68;




                let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2;
                let containerY = ('clientY' in event ? event.clientY : 0) - ref.current.getBoundingClientRect().top - tool_height - 10;


                var window_width = window.innerWidth;

                var window_height = window.innerHeight;

                if (event.clientX + tool_width / 2 > window_width) {
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2 - (event.clientX + tool_width / 2 - window_width);

                }
                if (event.clientX - tool_width / 2 < 0) {
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2 + (tool_width / 2 - event.clientX);
                }

                showTooltip({
                    tooltipLeft: containerX,
                    tooltipTop: containerY,
                    tooltipData: tooltipdata,
                    open: true,
                    show: tooltip.open && tipref && tipref.current ? true : false,
                    tipPostion: tipPosition
                });
            });
    }

    const color = "#BF8EE8";

    const handleScroll = ()=>{
        showTooltip({ ...tooltip, open: false, show: false })
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return ()=>{
            window.removeEventListener('scroll', handleScroll);
        }
    },[]);
    const renderChart = (option)=>{

        var exist = false;

        for(var i=0; i<data.length; i++){
            if(data[i].x === option){
                exist = true;
            }
        }

        if(exist){
            return <>
                <RatingScaleIcon selected={true} type={question.type=="Numeric"?"numbers":(question.type=="Stars"?"stars":question.type)} value={option} scale={question.scale}/>
                <div className="subquestion-chart-outer"
                     onPointerMove={handlePointerMove(data.filter(function (el) {
                         return el.x == option;
                     })[0].y == 1 ?
                         data.filter(function (el) {
                             return el.x == option;
                         })[0].y + " participant"
                         :
                         data.filter(function (el) {
                             return el.x == option;
                         })[0].y + " participants")}
                     onMouseOver={handlePointerMove(data.filter(function (el) {
                         return el.x == option;
                     })[0].y == 1 ?
                         data.filter(function (el) {
                             return el.x == option;
                         })[0].y + " participant"
                         :
                         data.filter(function (el) {
                             return el.x == option;
                         })[0].y + " participants")}
                     onPointerLeave={() => { showTooltip({ ...tooltip, open: false, show: false }) }}
                     onMouseOut={() => { showTooltip({ ...tooltip, open: false, show: false }) }}
                >
                    <div
                        className={`subquestion-chart-inner ${data.filter(function (el) {
                            return el.x == option;
                        })[0].percent_participants>90?"rating-chart-vip-label":""} `}
                        style={{
                            height: data.filter(function (el) {
                                return el.x == option;
                            })[0].percent_participants+"%",
                            width: data.filter(function (el) {
                                return el.x == option;
                            })[0].percent_participants+"%",
                            backgroundColor: color
                        }}


                    >


                        <Text type="h4">
                            {data.filter(function (el) {
                                return el.x == option;
                            })[0].percent_participants+"%"}
                        </Text>

                    </div>


                </div>
            </>
        } else {
            return <></>;
        }
    }
    return (
        <div className="rating-scale-chart-hold position-relative">
            <div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, "Rating Scale")}>
                <Icon value={'download'} size={'small'} hover={'true'}></Icon>
                <div className="tooltip-wrapper top-tooltip">
                    <p class="body-text body-text-2 normal-font ">Download</p>
                </div>
            </div>

            <div ref={exportPngRef}>
                <div className="likert-scale-chart ranking-scale-chart"
                     ref={ref}

                     style={{ position: 'relative' }}

                >


                    <div key={"rating-scale-chart"}  className="likert-scale-chart-accordion">
                        <div className="likertscale-subquestion-chart"  id="ratingscale-chart">
                            {options.map((option, i) => (
                                <div key={"chart-option-" + i}

                                >
                                    {renderChart(option)}


                                </div>
                            ))}

                        </div>
                        <div className="rating-scale-labels d-flex justify-content-between resule-rating-scale-labels">
                            {question.labels.map((item, index)=>{

                                return (
                                    <div className="scale-label" id={"label-"+index}>
                                        <Text type="body-text-3" fontWeight="medium-font" cssClasses={test.language == "ar"? "arabic-font" : ""}>{item}</Text>
                                    </div>
                                );
                            })}

                        </div>
                        <div className="rating_scale_calculation">
                            {predictions.csat!=null  &&
                            <div className="rating_scale_calculation_repeat gray-box">
                                <div className="rating_scale_calculation-left d-flex align-items-center">
                                    <Text type="body-text-1" fontWeight="medium-font" cssClasses="black-color">Satisfaction score</Text>
                                    <span className='tooltip-hold-wrap position-relative cursor-pointer'>
                                <Icon value="Information" colorClass={'gray-700-svg'}/>
                                <Tooltip
                                    richText={true}
                                    data={<>
                                        This rating measures the level of approval/satisfaction participants have expressed toward your question.
                                        <br/><br/>
                                        <strong>Calculation = (*Satisfied users / Total users asked)x100</strong><br/><br/>
                                        *Satisfied users: Who have selected 9 or 10 on a 10-point scale or 4 or 5 on a 5-point scale.
                                    </>}



                                    type="bottom"
                                />
                            </span>
                                </div>

                                <div className="rating_scale_calculation-right d-flex align-items-center">

                                    {question.type=="emoticons" &&
                                    <div className='rating_scale_calculation_icon'>
                                        <Icon value="emoticon_10_9"/>
                                        <Icon value="emoticon_10_10"/>
                                    </div>
                                    }
                                    <Text type="body-text-1" fontWeight="medium-font" cssClasses="gray-color">{predictions.csat}</Text>
                                </div>

                            </div>
                            }
                            {predictions.average!=null &&
                            <div className="rating_scale_calculation_repeat gray-box">
                                <div className="rating_scale_calculation-left d-flex align-items-center">
                                    <Text type="body-text-1" fontWeight="medium-font" cssClasses="black-color">Average</Text>
                                    <span className='tooltip-hold-wrap position-relative cursor-pointer'>
                                <Icon value="Information" colorClass={'gray-700-svg'}/>
                                <Tooltip
                                    richText={true}
                                    data={question.type=="Stars"?<>Sum of stars given / Number of total responses</>:<>Sum of ratings given / Number of total responses</>}
                                    type="bottom"/>
                            </span>
                                </div>

                                <div className="rating_scale_calculation-right d-flex align-items-center">
                                    {(question.type=="emoticons" || question.type=="Stars") &&
                                    <RatingScaleIcon selected={true} type={question.type=="Stars"?"star":question.type} value={predictions.average} scale={question.scale}/>
                                    }
                                    <Text type="body-text-1" fontWeight="medium-font" cssClasses="gray-color">{predictions.average}</Text>
                                </div>
                            </div>
                            }
                            {predictions.nps!=null &&
                            <div className="rating_scale_calculation_repeat gray-box">
                                <div className="rating_scale_calculation-left d-flex align-items-center">
                                    <Text type="body-text-1" fontWeight="medium-font" cssClasses="black-color">Net score</Text>
                                    <span className='tooltip-hold-wrap position-relative cursor-pointer'>
                            <Icon value="Information" colorClass={'gray-700-svg'}/>
                                <Tooltip
                                    richText={true}
                                    data={<>
                                        This rating measures the net difference between the 2 extreme responses on the rating scale. For example, in the case of a satisfaction scale, the extremes that are considered will be very satisfied, minus very dissatisfied.
                                        <br/><br/>
                                        <strong>Calculation = % Enthusiasts - % Critics</strong></>}
                                    type="bottom"/>
                            </span>
                                </div>

                                <div className="rating_scale_calculation-right d-flex align-items-center">
                                    {question.type=="emoticons" &&
                                    <div className="rating_scale_calculation_icon">
                                        {predictions.nps <= 0
                                            ?
                                            <Icon value="emoticon_10_1"/>
                                            :
                                            (
                                                predictions.nps <= 29
                                                    ?
                                                    <Icon value="emoticon_7_4"/>
                                                    :
                                                    (
                                                        predictions.nps <= 70
                                                            ?
                                                            <Icon value="emoticon_10_9"/>
                                                            :
                                                            <Icon value="emoticon_10_10"/>
                                                    )
                                            )
                                        }
                                    </div>

                                    }
                                    <Text type="body-text-1" fontWeight="medium-font" cssClasses="gray-color">{predictions.nps}</Text>
                                </div>
                            </div>
                            }

                        </div>

                    </div>
                </div>
            </div>

            {tooltip.open ? (
                <>
                    <Tooltip
                        ref={tipref}
                        type={tooltip.tipPosition}
                        key={Math.random()} // needed for bounds to update correctly
                        style={{ zIndex: 0, display: tooltip.show ? "block" : 'block', whiteSpace: "nowrap", width: "auto", minWidth: "150px", margin: 0, position: "absolute", left: (tooltip.tooltipLeft) + "px", top: (tooltip.tooltipTop) + "px" }}
                        data={tooltip.tooltipData}
                        className="tooltipChart"

                    />
                </>
            ) : (
                <></>
            )}

        </div>
    )
}