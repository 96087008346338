import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import Radiobtn from "../../../layouts/elements/fields/radiobtn";
import toast from 'react-hot-toast';
import { Link } from "react-router-dom";
import CheckboxNormal from "../../../layouts/elements/fields/checkbox_normal";
import { FormattedMessage } from "react-intl";
import {
    atom,
    useRecoilState,
    useResetRecoilState
} from 'recoil';
import { roundNumber } from "../../../../lib/helpers";
import { Text } from './../../../themes/userq/Text/Text';
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";

export default function PublishModal({ open, close, test, confirm, testmethodology,targetType }) {
    let user = ReactSession.get("user");
    const wrapperRef = useRef(null);
    const [formValues, setFormValues] = useState({ "checked_all_test": false, "accepted_terms": false })
    //const [credits, setCredits] = useState((ReactSession.get("credits"))?ReactSession.get("credits"):0)

    const creditsState = atom({
        key: 'credits',
        default: 0
    });
    const [credits, setCredits] = useRecoilState(creditsState);


    const resetCredits = useResetRecoilState(creditsState);

    const showError = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 3000,
            }
        );
    };

    useEffect(() => {
        if (!open) {
            setFormValues({ "checked_all_test": false, "accepted_terms": false });
        }
    }, [open]);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                // console.log(event.target);
                // console.log(event.target.nodeName);
                // console.log("xyz");
                // console.log(ref.current);
                if (ref.current && !ref.current.contains(event.target)) {
                    // console.log("abc");
                    if(event.target.nodeName =="DIV"){
                        // console.log("abcd");
                        close();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap confirmation-modal-wrap publish-modal-wrap"
        >
            <Modal.Body className="" ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        {testmethodology == "Survey" ? (
                            <Text type="h3">Publish your survey</Text>
                        )
                            :
                            (
                                <Text type="h3">Publish your test</Text> 
                            )
                        }


                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                close();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value={'Close'} size={'meidum'} hover={'true'}/>
                               
                            </span>
                        </button>


                    </div>


                    <div className="publish-test-modal-text">
                        <Text type="body-text-1" fontWeight={'medium-font'} cssClasses={'mb-20 secondary-purple-200'}>Ready for your test to go live? So are we!</Text>
                        <div class="publish-test-modal-data"> 

                            <div class="publish-modal-image-wrap">
                                <img src={process.env.REACT_APP_URL + "img/publish-modal-checklist.gif"} alt="img" />
                            </div>

                            <div class="publish-modal-text">
                                {targetType=="test-links"?
                                <>
                                <Text type="body-text-3" fontWeight="medium-font">You will start getting responses as soon as you share the test link. You can close the test at any time from your dashboard.</Text>
                                <Text type="body-text-3" fontWeight="medium-font">Once the test is live, it cannot be modified.</Text>
                                </>
                                :
                                <>
                                <Text type="body-text-3" fontWeight="medium-font">You will start getting responses within a few minutes of publishing your test. You can close the test at any time from your dashboard.</Text>
                                <Text type="body-text-3" fontWeight="medium-font">Once the test is live, it cannot be modified.</Text>
                                </>
                                }
                                
                            </div>
                        </div>
                        <div className="confirm-buttons-wrap publish-modal-btn">
                            <Button
                                size="large"
                                type="secondary"
                                label="Cancel"
                                onClick={() => {
                                    close();
                                }}
                            />
                            <Button
                                size="large"
                                type="primary"
                                label="Confirm"
                                onClick={() => {
                                    confirm();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}