import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import { Helmet } from "react-helmet";
import { Text } from "../../themes/userq/Text/Text";
import { Icon } from "../../themes/userq/Icon/Icon";
import { deleteWorkspaceService } from "../../../services/workspaces";
import { ReactSession } from "../../../lib/secure_reactsession";
import { showToast } from "../../../lib/helpers";
import "./delete-workspace.css";

const DeleteWorkspace = () => {
    ReactSession.setStoreType("localStorage");
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const navigate = useNavigate();
    const [currentValue, setCurrentValue] = useState(0);
    const maxValue = 100;
    const [workspaceName, setWorkspaceName] = useState("workspace");

    useEffect(() => {
        let workspaceRemoval = ReactSession.get("workspace_removal");
        if (
            workspaceRemoval &&
            workspaceRemoval.signature &&
            workspaceRemoval.workspace_id &&
            workspaceRemoval.type
        ) {
            setWorkspaceName(workspaceRemoval.name);
            deleteWorkspace(
                workspaceRemoval.signature,
                workspaceRemoval.workspace_id,
                workspaceRemoval.type
            );
        } else {
            navigate("/404");
        }
    }, []);

    // const increment = useCallback(() => {
    //     if (currentValue === 100) {
    //         // setCurrentValue(0);
    //     } else {
    //         if (currentValue < 80) {
    //             setCurrentValue((v) => v + 5);
    //         }
    //     }
    // }, [setCurrentValue, currentValue]);

    // useEffect(() => {
    //     const r = setInterval(() => {
    //         increment();
    //     }, 500);

    //     return () => {
    //         clearInterval(r);
    //     };
    // }, [increment]);

    const deleteWorkspace = (
        signature = null,
        workspaceId = null,
        type = null
    ) => {
        ReactSession.set("workspace_removal", null);
        let token = ReactSession.get("token");
        let formData = {};
        formData.signature = signature;
        formData.workspace_id = workspaceId;

        deleteWorkspaceService(formData, token, (percentage) => {
            setCurrentValue(percentage);
        })
            .then((response) => {
                if (response.success) {
                    if (response.workspace_id) {
                        setTimeout(() => {
                            showToast(response.message, "success");
                            navigate("/wsp/" + response.workspace_id);
                        }, 1000);
                    }
                } else {
                    if (type === "Team") {
                        navigate("/wsp/" + workspaceId + "/team-&-settings");
                    } else {
                        navigate("/wsp/" + workspaceId + "/settings");
                    }

                    showToast(response.message, "error");
                }
            })
            .catch((error) => {
                // showToast(error, "error");
                if (type === "Team") {
                    navigate("/wsp/" + workspaceId + "/team-&-settings");
                } else {
                    navigate("/wsp/" + workspaceId + "/settings");
                }
            });
    };

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            profileHeader={false}
            activeMenu={"dashboard"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
            wrapClass={"delete-account-progress-wrap"}
        >
            <Helmet>
                <title>Delete Workspace | UserQ</title>
            </Helmet>

            <>
                <div className="delete-account-wrap">
                    <div className="account-delete-icon">
                        <Icon
                            value={"delete"}
                            colorClass={"gray-900-svg"}
                            size={"large"}
                        />
                        <Text type={"subtitle-2"} cssClasses={"mt-32 d-block"}>
                            Deleting {workspaceName}
                        </Text>
                    </div>

                    <div className={`progress-bar mt-60 mb-20 ${currentValue}`}>
                        <span class="bar">
                            <span
                                class="progress"
                                style={{
                                    width: `${
                                        currentValue <= maxValue
                                            ? currentValue
                                            : maxValue
                                    }%`,
                                }}
                            ></span>
                        </span>
                    </div>
                    <Text type={"body-text-3"} fontWeight={"medium-font"}>
                        {"Deleting projects..."}
                    </Text>
                </div>
            </>
        </LayoutResearcher>
    );
};

export default DeleteWorkspace;
