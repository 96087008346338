import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactSession } from "../../lib/secure_reactsession";
import React, { useEffect, useRef, useState } from "react";
import { encryptId } from "../../lib/helpers.js";
import { Text } from "../themes/userq/Text/Text";
import { Icon } from "../themes/userq/Icon/Icon";
import { Button } from "../themes/userq/Button/Button";
import { Tooltip } from "../themes/userq/Tooltip/Tooltip";
import toast from "react-hot-toast";
import {ToastMessage} from "../themes/userq/ToastMessage/ToastMessage";
import HelpModal from "../themes/userq/Modal/HelpModal";
import {getExpiryDocumentOnfidoParams, expiryDocumentOnfidoResult} from "../../services/id_verification";
import {Onfido} from "onfido-sdk-ui";
import {showToast} from "../../lib/helpers";
import {CountdownTimer} from "./CountdownTimer";
import moment from 'moment';
var onfido = {};

export const ExpiryId = ({profileExpiredDate,isProfileVerifiedAt,isProfileAttempts,confirmHandler})=>{


    const navigate = useNavigate();


    let user = ReactSession.get("user");

    const [limitReachedModal, setLimitReachedModal] = useState({open:false});
    const [isLoading, setLoading] = useState(false);

    const [profileVerifiedAt, setProfileVerifiedAt] = useState(isProfileVerifiedAt);
    const [profileAttempts, setProfileAttempts] = useState(isProfileAttempts);

    //  timer functionality
    const [timer, setTimer] = useState(0);
    let clearTimer;

    const [onfidoModal, setOnfidoModal] = useState({open:false});

    useEffect(() => {

        if(timer>0){
            countDown();
        }

    }, [timer]);

    useEffect(() => {
        return () => {
            if(clearTimer){
                clearTimeout(clearTimer);
            }
        };
    },[])

    useEffect(()=>{


        if(isProfileVerifiedAt){

            if(clearTimer){
                clearTimeout(clearTimer);
            }
            let date1 = moment();
            let date2 = moment(isProfileVerifiedAt).utc(true);
            let difference = date1.diff( date2, "seconds" );
            console.log(24*60*60 - difference)
            if(24*60*60 - difference < 0){
                setTimer(0);
            }else{
                setTimer(24*60*60 - difference);
            }


        }



    },[isProfileVerifiedAt])

    const countDown = ()=>{

        if(clearTimer){
            clearTimeout(clearTimer);
        }
        clearTimer = setTimeout(()=>{

            setTimer(timer-1);

        },1000);
    }

    const formatTime =(seconds)=>{

        if(isNaN(seconds)){
            return '00:00:00';
        }

        const result = new Date(seconds * 1000)
            .toISOString()
            .slice(11, 19);

        return result;
    }

    useEffect(()=>{

        if(isProfileVerifiedAt){
            setProfileVerifiedAt(isProfileVerifiedAt);
        }

        if(isProfileAttempts){
            setProfileAttempts(isProfileAttempts);
        }


    },[isProfileVerifiedAt,isProfileAttempts]);


    const getOnfidoParamsApi = () => {

        setLoading(true);

        const token = ReactSession.get("token");

        setLimitReachedModal({...limitReachedModal, open:false});

        getExpiryDocumentOnfidoParams(token)
            .then(response => {
                setLoading(false);

                if (response.success) {

                    setOnfidoModal({...onfidoModal, open: true});

                    document.body.classList.add('modal-open');

                    onfido = Onfido.init({
                        token: response.data.sdk_token,
                        //useModal: true,
                        //isModalOpen: true,
                       /* onModalRequestClose: function() {
                            //alert(1)
                            // Update options with the state of the modal
                            onfido.setOptions({isModalOpen: false})
                        },*/
                        enterpriseFeatures: {
                            logoCobrand: {
                                darkLogoSrc: "https://userq.com/wp-content/uploads/2022/12/UserQ-logo-positive-small.png",
                                lightLogoSrc: "https://userq.com/wp-content/uploads/2022/12/UserQ-logo-positive-small.png",
                            }
                        },
                        onComplete: function (data) {
                            expiryDocumentHandler();

                        },
                        workflowRunId: response.data.workflow_run_id,
                    })

                } else {

                    showToast(response.message,'error');
                }
            })


    }


    const expiryDocumentHandler = ()=>{

        setLoading(true);

        const token = ReactSession.get("token");

        expiryDocumentOnfidoResult(token)
            .then(response => {
                setLoading(false);

                if (response.success) {

                    //onfido.setOptions({isModalOpen: false})

                    // console.log(response.data)

                    setOnfidoModal({...onfidoModal, open: false});

                    document.body.classList.remove('modal-open');

                    if(response.data.user) {


                         let user = ReactSession.get("user");
                        //
                        user.profile_verification_status = response.data.user.profile_verification_status;
                        user.first_name = response.data.user.first_name;
                         user.last_name = response.data.user.last_name;
                         //user.profile_verified_at = (response.last_attempt)?response.last_attempt:'';
                        ReactSession.set("user", user);

                        if(response.data.user.profile_verification_status == 'verified' && response.expired_document_attempt === 0){

                            showToast('Your ID document has been updated','success');

                            setProfileAttempts(response.expired_document_attempt);

                            confirmHandler();

                        }else if(response.expired_document_attempt === 1){

                            setProfileAttempts(response.expired_document_attempt);
                            setLimitReachedModal({...limitReachedModal, open:true});
                        }else if(response.expired_document_attempt === 2){

                            setProfileAttempts(response.expired_document_attempt);
                            setProfileVerifiedAt(response.expired_document_retry_after_date)
                            // setLimitReachedModal({...limitReachedModal, open:true});
                        }else{

                            navigate('/dashboard');
                        }


                    }

                } else {
                    showToast(response.message,'error');
                }
            })

    }

    return (
        <>
            {profileAttempts === 2 && formatTime(timer) !== '00:00:00' && profileVerifiedAt ?
                <ToastMessage icon={"id"} colorClass={"secondary-purple-200-svg"} type={"info"} message={
                    <><p>
                        To ensure the security and privacy of our platform, we highly encourage you to verify your
                        ID. Try again in
                        {" "}<CountdownTimer timerData={profileVerifiedAt}/>
                    </p></>} closable={false}/>
                :
                <>
                    {!profileExpiredDate &&
                    <ToastMessage icon={"id"} colorClass={"tertiary-gold-200-svg"} type={"info"} cssClasses={'gold-info'} message={
                        <><p>
                            Your ID document has expired.{" "}
                            <span onClick={()=> getOnfidoParamsApi()}
                                  className="link-text">
                           Upload a valid document
                       </span>
                            {" "}before requesting your next payout.
                        </p></>} closable={false} />
                    }

                    {profileExpiredDate !== 0 && profileExpiredDate <= 15 &&
                    <ToastMessage icon={"id"} colorClass={"tertiary-gold-200-svg"} type={"info"} message={
                        <><p>
                            {profileExpiredDate===1 ?'Your ID document will expire today':`Your ID document will expire in ${profileExpiredDate} days`}.{" "}
                            <span onClick={()=>getOnfidoParamsApi()}
                                  className="link-text">
                               Upload a valid document
                           </span>
                            {" "}before requesting your next payout.
                        </p></>} closable={false} />
                    }

                </>

            }


            {onfidoModal.open &&
            <div className='onfido-modal'>
                 <div className="onfido-backdrop-shadow"></div>
                <div className='onfido-modal-body'>
                    <span className='close-button-onfido' onClick={()=>{ document.body.classList.remove('modal-open'); setOnfidoModal({...onfidoModal, open:false})}}>
                        <Icon value="Close" size="large" hover={true} />
                    </span>

                    <div id="onfido-mount"></div>
                </div>
            </div>
            }


            <HelpModal
                cssClass={"limit-reached-modal"}
                open={limitReachedModal.open}
                close={()=>{
                    setLimitReachedModal({...limitReachedModal, open:false});
                }}
                disableCross={true}
                body={
                    <div className="prototype-criteria-modal-data">
                        <div className="mb-20 text-center">
                            <Text  type={"h2"} fontWeight={"medium-font"}>
                                The uploaded document doesn’t match your verified information
                            </Text>
                        </div>
                        <div className="d-flex justify-content-center text-center align-items-center mt-40 mb-40">
                            <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={'grey-text'}>
                                Please make sure to upload a valid document that matches<br/>
                                the verified information on your profile.
                            </Text>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mb-40">
                            <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={'text-center grey-text'}>
                                Attempts remaining: 1
                            </Text>
                        </div>

                        <div className="d-flex justify-content-center duplicate-test-modal-action">
                            <Button
                                size={"large"}
                                type="secondary"
                                label="Back to dashboard"
                                onClick={() => {

                                    navigate('/dashboard');
                                }}
                            />
                            <Button
                                size={"large"}
                                type="primary"
                                iconLeft={<Icon value="id-doc-white" />}
                                label="Upload new document"
                                onClick={() => {
                                    getOnfidoParamsApi()
                                }}
                            />
                        </div>

                    </div>

                }
            />
        </>
    );
}
