import React, { useState, useRef } from "react";
import { Accordian } from './../Accordion/Accordion';
import { Text } from './../Text/Text';
import { Tooltip } from './../Tooltip/Tooltip';
import { Icon } from "../../../themes/userq/Icon/Icon";
import exportAsImage from "../../../../lib/exportAsImage";

export const LikertScaleAggregateData = ({ test, data, likertQuestion }) => {

    const ref = useRef();
    const tipref = useRef();
    const exportPngRef = useRef();


    const [tooltip, showTooltip] = useState({
        tooltipLeft: 0,
        tooltipTop: 0,
        tooltipData: "",
        open: false,
        show: false
    });

    const handlePointerMove = (tooltipdata) => {


        return (
            (event) => {


                let tipPosition = "bottom";

                var tool_width = tipref && tipref.current ? tipref.current.clientWidth : 150;


                var tool_height = tipref && tipref.current ? tipref.current.clientHeight + 12 : 106;




                let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2;
                let containerY = ('clientY' in event ? event.clientY : 0) - ref.current.getBoundingClientRect().top - tool_height - 10;


                var window_width = window.innerWidth;

                var window_height = window.innerHeight;

                if (event.clientX + tool_width / 2 > window_width) {
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2 - (event.clientX + tool_width / 2 - window_width);

                }
                if (event.clientX - tool_width / 2 < 0) {
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2 + (tool_width / 2 - event.clientX);
                }

                showTooltip({
                    tooltipLeft: containerX,
                    tooltipTop: containerY,
                    tooltipData: tooltipdata,
                    open: true,
                    show: tooltip.open && tipref && tipref.current ? true : false,
                    tipPostion: tipPosition
                });
            });
    }

    return (
        <div className="position-relative likret-scale-chart-wrap aggregate-data-wrap">
            <div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, "Aggregated data")}>
                <Icon value={'download'} size={'small'} hover={'true'}></Icon>
                <div className="tooltip-wrapper top-tooltip">
                    <p class="body-text body-text-2 normal-font ">Download</p>
                </div>
            </div>
            <div className="likert-scale-aggregated-data">
                <table ref={exportPngRef}>
                    <thead>
                    <tr>
                        <th className="likert-chart-corner"></th>
                        {likertQuestion.options && likertQuestion.options.map((option, i) => (
                            <th key={i} className="likert-options"><div className="likert-option-th">{option}</div></th>
                        ))}

                    </tr>
                    </thead>
                    <tbody ref={ref} style={{ position: 'relative' }}>
                    {likertQuestion.subQuestions && likertQuestion.subQuestions.map((element, index) => (
                        <tr>
                            <td className="likert-statements">{element}</td>
                            {likertQuestion.options && likertQuestion.options.map((option, i) => (
                                <td >
                                    <div
                                        className={`likert-agg-tbl-box ${data.filter(function (el) {
                                            return el.x == option &&
                                                el.y == element;
                                        })[0].percent_participants > 60 ? "white-text" : ""}`}
                                        style={{
                                            backgroundColor: data.filter(function (el) {
                                                return el.x == option &&
                                                    el.y == element;
                                            })[0].fill
                                        }}
                                        onPointerMove={handlePointerMove(data.filter(function (el) {
                                            return el.x == option &&
                                                el.y == element;
                                        })[0].heat == 1 ?
                                            data.filter(function (el) {
                                                return el.x == option &&
                                                    el.y == element;
                                            })[0].heat + " participant"
                                            :
                                            data.filter(function (el) {
                                                return el.x == option &&
                                                    el.y == element;
                                            })[0].heat + " participants")}
                                        onPointerLeave={() => { showTooltip({ ...tooltip, open: false, show: false }) }}
                                    >

                                        <Text type="h4">
                                            {data.filter(function (el) {
                                                return el.x == option &&
                                                    el.y == element;
                                            })[0].percent_participants + "%"}
                                        </Text>
                                        {/* <Tooltip
                                        data={data.filter(function (el) {
                                            return el.x == option &&
                                                el.y == element;
                                        })[0].heat > 1 ?
                                            data.filter(function (el) {
                                                return el.x == option &&
                                                    el.y == element;
                                            })[0].heat + " participants"
                                            :
                                            data.filter(function (el) {
                                                return el.x == option &&
                                                    el.y == element;
                                            })[0].heat + " participant"
                                        }
                                        type={"bottom"}

                                    /> */}

                                    </div>

                                </td>
                            ))}
                        </tr>
                    ))}
                    {tooltip.open ? (
                        <>
                            <Tooltip
                                ref={tipref}
                                type={tooltip.tipPosition}
                                key={Math.random()} // needed for bounds to update correctly
                                style={{ zIndex: 0, display: tooltip.show ? "block" : 'block', whiteSpace: "nowrap", width: "auto", minWidth: "150px", margin: 0, position: "absolute", left: (tooltip.tooltipLeft) + "px", top: (tooltip.tooltipTop) + "px" }}
                                data={tooltip.tooltipData}
                                className="tooltipChart"

                            />
                        </>
                    ) : (
                        <></>
                    )}
                    </tbody>

                </table>

            </div>
        </div>
    )
}