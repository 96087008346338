import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import Frame from "../study/frame/frame.js";
import Progress from "../study/progress/progress.js";
import Topbar from "../study/topbar/topbar.js";
import StudyTargetModal from "../study/targetmodal/study-target-modal.js";
import React, { useEffect, useRef, useState } from "react";
import { getTestData, saveReview } from "../../../services/test";
import moment from "moment";
import toast from "react-hot-toast";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import redirectToStep from "./redirect_to_step";
import { LoadingIcon } from "../../loader/loadingIcon";

export default function TargetGroupTest() {
    let { test_id } = useParams();
    const navigate = useNavigate();

    ReactSession.setStoreType("localStorage");

    const [isLoading, setLoading] = useState(false);
    const [lastSaved, setLastSaved] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);
    const [reviewStepLoading, setReviewStepLoading] = useState(false);
    const [briefingQuestion, setBriefingQuestion] = useState([]);
    const [deBriefingQuestion, setDeBriefingQuestion] = useState([]);
    const [surveyQuestion, setSurveyQuestion] = useState([]);
    const [fiveSecondsQuestion, setFiveSecondsQuestion] = useState([]);

    const [test, setTest] = useState("");

    const showError = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 3000,
            }
        );
    };

    const getTestApi = () => {
        let token = ReactSession.get("token");

        setPageLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setPageLoading(false);

            console.log(response.test.target_group)
            if (response.success) {
                if (response.test.status) {
                    if (response.test.status == "completed") {
                        //navigate("/r/project/" + response.test.project_id);

                        navigate("/dashboard");
                        
                    } /*else {
                        if (
                            response.test.is_briefing_questions == 1 &&
                            response.test.briefingQuestion.length == 0
                        ) {
                            navigate("/r/test/" + test_id + "/welcome");
                            showError("Please add briefing questions");
                        } else if (response.test.methodology == "Tree Test" && response.test.tasks.length == 0) {
                            navigate("/r/test/" + test_id + "/tree-test/");
                            showError("Please add tasks");
                        } else if (response.test.methodology === "Card Sorting") {

                            if (response.test.card_sorting_card.length === 0) {
                                navigate("/r/test/" + test_id + "/card-sorting-test/");
                                showError("Looks like you haven't created card for this test! Please add a card to move to the next steps.");
                            } else if (response.test.card_sorting.card_sorting === 'close' && response.test.card_sorting_category.length === 0) {
                                navigate("/r/test/" + test_id + "/card-sorting-test/");
                                showError("Looks like you haven't created category for this test! Please add a category to move to the next steps.");

                            }
                            else if (
                                response.test.is_debriefing_questions == 1 &&
                                response.test.debriefingQuestion.length == 0
                            ) {
                                navigate("/r/test/" + test_id + "/thank-you");
                                showError("Please add debriefing questions");
                            }
                            else if (response.test.target_group == null) {
                                navigate("/r/test/" + test_id + "/recruit/");
                                showError("Please complete the recruitment step");
                            } else if (
                                response.test.target_group != null &&
                                response.test.target_group.target_type !== 'test-links'
                            ) {
                                if (response.test.target_group.participants_selected == null) {
                                    navigate("/r/test/" + test_id + "/recruit/");
                                    showError("Please complete the recruitment step");
                                } else if (response.test.target_group.participants_selected === 0) {
                                    navigate("/r/test/" + test_id + "/recruit/");
                                    showError(
                                        <span className={"w-100"}>
                                            Unfortunately, we can't fulfil your target group requirements.
                                            Please contact us at
                                            <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}> support@userq.com</a>
                                        </span>
                                    );
                                }
    
                            }

                        } else if (response.test.methodology === "Five Seconds Test") {
                            if (response.test.fiveSecondsTestTask == null) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Some required information for the five seconds test is missing or incomplete. Please review and try again."
                                );
                            }
                            else if (response.test.fiveSecondsTestTask.task == null) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                            } else if (response.test.fiveSecondsTestTask.task_file == null) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added design for this test! Please add design to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length === 0) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added questions for this test! Please add atleast 1 question to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                response.test.fiveSecondsTestQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    navigate(
                                        "/researcher/tests/" + test_id + "/five-seconds-test/"
                                    );
                                    showError(
                                        "Atleast one question should not be optional."
                                    );
                                }
                                else if (
                                    response.test.is_debriefing_questions == 1 &&
                                    response.test.debriefingQuestion.length == 0
                                ) {
                                    navigate("/r/test/" + test_id + "/conclusions");
                                    showError("Please add debriefing questions");
                                }
                                else if (response.test.target_group == null) {
                                    navigate("/r/test/" + test_id + "/recruit/");
                                    showError("Please complete the recruitment step");
                                } else if (
                                    response.test.target_group != null &&
                                    response.test.target_group.target_type !== 'test-links'
                                ) {
                                    if (response.test.target_group.participants_selected == null) {
                                        navigate("/r/test/" + test_id + "/recruit/");
                                        showError("Please complete the recruitment step");
                                    } else if (response.test.target_group.participants_selected === 0) {
                                        navigate("/r/test/" + test_id + "/recruit/");
                                        showError(
                                            <span className={"w-100"}>
                                                Unfortunately, we can't fulfil your target group requirements.
                                                Please contact us at
                                                <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}> support@userq.com</a>
                                            </span>
                                        );
                                    }
        
                                }

                            }
                            

                        }
                        else if (
                            response.test.methodology == "Survey" &&
                            response.test.surveyQuestion.length === 0
                        ) {
                            navigate("/r/test/" + test_id + "/survey");
                            showError("Please add survey questions");
                        }
                        else if (
                            response.test.is_debriefing_questions == 1 &&
                            response.test.debriefingQuestion.length == 0
                        ) {
                            navigate("/r/test/" + test_id + "/thank-you");
                            showError("Please add debriefing questions");
                        } else if (response.test.target_group == null) {
                            navigate("/r/test/" + test_id + "/recruit/");
                            showError("Please complete the recruitment step");
                        } else if (
                            response.test.target_group != null &&
                            response.test.target_group.target_type !== 'test-links'
                        ) {
                            if (response.test.target_group.participants_selected == null) {
                                navigate("/r/test/" + test_id + "/recruit/");
                                showError("Please complete the recruitment step");
                            } else if (response.test.target_group.participants_selected === 0) {
                                navigate("/r/test/" + test_id + "/recruit/");
                                showError(
                                    <span className={"w-100"}>
                                        Unfortunately, we can't fulfil your target group requirements.
                                        Please contact us at
                                        <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}> support@userq.com</a>
                                    </span>
                                );
                            }

                        } else if (
                            response.test.methodology == "Tree Test"
                        ) {
                            if (response.test.tasks.length === 0) {
                                navigate("/r/test/" + test_id + "/tree-test");
                                showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                            } else {
                                console.log(response.test.tasks);
                                for (var i = 0; i < response.test.tasks.length; i++) {
                                    if (response.test.tasks[i].correct_answer_id == null) {
                                        navigate("/r/test/" + test_id + "/tree-test");
                                        showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                        break;
                                    }
                                }

                            }
                        } else if (
                            response.test.methodology == "Preference Test"
                        ) {
                            if(response.test.preference_test_designs.length < 2){
                                navigate("/r/test/" + test_id + "/preference-test");
                                showError("There's some information we still need – please take a look to see if you missed anything!");
                            }
                        }
                    }*/
                }

                setTest(response.test);
                let briefingQuestions = [];
                let deBriefingQuestions = [];
                let surveyQuestions = [];
                let fiveSecondsQuestions = [];
                response.test.briefingQuestion.forEach((question) => {
                    briefingQuestions.push(JSON.parse(question));
                });
                response.test.debriefingQuestion.forEach((question) => {
                    deBriefingQuestions.push(JSON.parse(question));
                });
                response.test.surveyQuestion.forEach((question) => {
                    surveyQuestions.push(JSON.parse(question));
                });
                response.test.fiveSecondsTestQuestion.forEach((question) => {
                    fiveSecondsQuestions.push(JSON.parse(question));
                });

                setBriefingQuestion(briefingQuestions);
                setDeBriefingQuestion(deBriefingQuestions);
                setSurveyQuestion(surveyQuestions);
                setFiveSecondsQuestion(fiveSecondsQuestions);

                // redirectToStep({ test_id: test_id, current_step: 5, saved_step: response.test.saved_step, navigate: navigate });

                /*if(response.test.saved_step){
                            if(response.test.saved_step<4){
                                navigate("/r/test/"+test_id+"/recruit");
                            }
                        }*/

                setLastSaved(moment(response.test.updated_at));
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Review";

        getTestApi();
    }, []);

    const handleSubmit = (shouldnavigate) => {
        if (shouldnavigate) {
            setReviewStepLoading(true);
        }

        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test_id);

        saveReview(data, token).then((response) => {
            setReviewStepLoading(false);

            if (response.success) {
                if (shouldnavigate) {
                    navigate("/r/test/" + test_id + "/publish");
                }
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: response.success ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });

        return false;
    };
    return (
        <LayoutResearcher
            fixed_header_target={true}
            isLoading={false}
            wrapClass={test && test.language == "ar" ? "arabic_wrapper_main arabic_wrapper_preview" : ""}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
        >
            <div className="study-introduction-page-wrapper stydy-pages-wrapper study-review-page-wrapper pb-0">
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        <Topbar
                            credits_required={test.credits_required}
                            test={test}
                            onChange={(test) => {
                                setTest({ ...test });
                            }}
                            stepCallback={() => {
                                handleSubmit(false);
                            }}
                            status={test.status}
                            lastSaved={lastSaved}
                            path={"/r/test/" + test_id + "/recruit"}
                            isLoading={false}
                            pageLoading={pageLoading}
                            title={test.test_name}
                        />
                    </div>
                </div>
                <div className="container inner-page-container">
                    {/*<div className="study-steps-progress-wrap">
                        (
                            <Progress
                                stepCallback={() => {
                                    handleSubmit(false);
                                }}
                                test={test}
                                completed_step={4}
                            />
                            )
                    </div>*/}
                    {pageLoading &&
                        <LoadingIcon />
                    }
                    {!pageLoading &&
                        <div className="target-group-data-wrapper study-target-group-wrap">
                            <div className="targer-group-left-wrap w-100">
                                <div className="target-group-data-repeat study-target-repeat">
                                    <div className="target-form-wrap w-100">
                                        <div className="row study-review-summary-heading">
                                            <div className="col-md-12">
                                                <h3 className="study-review-left-heading mb-0">
                                                    Project summary
                                                </h3>
                                            </div>
                                        </div>

                                        <div className="summary-selected-lan-wrap">
                                            <div className="row">
                                                <div className="col-6 col-md-5 col-lg-5 sm-lan-head-wrap">
                                                    <p className="page-step-count">Test language</p>
                                                </div>
                                                <div className="col-6 col-md-7 col-lg-7 sm-selected-lan-wrap">
                                                    <span className="button small-button secondary-btn font-inter">
                                                        {test.language === "en" ? "English" : "Arabic"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="target-group-data-repeat study-target-repeat">
                                    <div className="target-form-wrap w-100">
                                        <div className="row">
                                            <div className="col-md-5 col-lg-5">
                                                <div className="study-review-left-info-wrap">
                                                    <p className="page-step-count">Page 1</p>
                                                    <h3 className="study-review-left-heading">
                                                        Welcome page
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="col-md-7 col-lg-7">
                                                <div className={`study-review-right-info-wrap ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="uploaded-project-img study-review-image-wrap">
                                                                <img
                                                                    src={
                                                                        test.image
                                                                            ? process.env.REACT_APP_IMG_URL + test.image
                                                                            : process.env.REACT_APP_URL +
                                                                            "images/welcome-placeholder.png"
                                                                    }
                                                                    alt="img"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="study-review-welcome-text">
                                                                <h3 className="h3">
                                                                    {test.welcome_title}
                                                                </h3>
                                                                <p className="review-study-small-text">
                                                                    {test.welcome_description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {test.methodology != "Survey" && (
                                    <div className="target-group-data-repeat study-target-repeat">
                                        <div className="target-form-wrap w-100">
                                            <div className="row">
                                                <div className="col-md-5 col-lg-5">
                                                    <div className="study-review-left-info-wrap">
                                                        <p className="page-step-count">Page 2</p>
                                                        <h3 className="study-review-left-heading">
                                                            Briefing questions
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-lg-7">
                                                    <div className={`study-review-right-info-wrap ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                                        <div className="added-question-wrap">
                                                            {briefingQuestion.length === 0 ?
                                                                <p className={"info-not-available big-font"}>You have not
                                                                    added any briefing questions to your test </p>
                                                                : ''}
                                                            {briefingQuestion &&
                                                                briefingQuestion.map((items, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="ans-list-repeat saved-ans-repeat flex-wrap"
                                                                        >
                                                                            <div className="ques-count">
                                                                                {index + 1}
                                                                            </div>
                                                                            <div className="ans-data">
                                                                                <p className="small-info-heading">
                                                                                    {items.questionType === "singlechoice"
                                                                                        ? "Single choice question"
                                                                                        : items.questionType === "multiplechoice"
                                                                                            ? "Multi choice question"
                                                                                            : items.questionType === "likertscale"
                                                                                                ? "Likert scale"
                                                                                                : "Free text"}
                                                                                </p>
                                                                                {items.question}
                                                                            </div>
                                                                            <div className="answer-review-repeat w-100">
                                                                                {items.questionType != "likertscale" && items.options &&
                                                                                    items.options.map((option) => {
                                                                                        return (
                                                                                            <div
                                                                                                className="review-answer-hold-count">
                                                                                                <span>
                                                                                                    <img
                                                                                                        src={
                                                                                                            process.env.REACT_APP_URL +
                                                                                                            "images/Polygon-bg.svg"
                                                                                                        }
                                                                                                    />
                                                                                                </span>
                                                                                                <p>{option}</p>

                                                                                            </div>

                                                                                        );
                                                                                    })}

                                                                                <div
                                                                                    className="likert-question-data-hold likert-view-wrap answer-review-repeat w-100 likert-review-wrap">

                                                                                    {items.questionType == "likertscale" && (
                                                                                        <div class="survey-rating-options-wrap">
                                                                                            <div class="bd-question-radio-data">
                                                                                                <div
                                                                                                    class="checkboxes radio_btn_class  d-flex rating-answer-options-radio mb-0 mt-0">

                                                                                                    <div
                                                                                                        class="ans-list-wrap likert-scale-option-add likert-scale-top-input-wrap">
                                                                                                        <div
                                                                                                            className="ans-list-repeat saved-ans-repeat">

                                                                                                            <div
                                                                                                                class="likert-option-left">
                                                                                                                <div
                                                                                                                    class="ans-icon">
                                                                                                                    &nbsp;
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="ans-data">
                                                                                                                    &nbsp;
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                class="likert-option-right">
                                                                                                                {items.options.map((option, index) => (
                                                                                                                    <label
                                                                                                                        for="radio4">
                                                                                                                        <p>{option}</p>
                                                                                                                    </label>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                    {items.questionType == "likertscale" && items.subQuestions.map(
                                                                                        (subQuestion, index) => (

                                                                                            <div
                                                                                                class="ans-list-wrap likert-scale-option-add">
                                                                                                <div
                                                                                                    class="ans-list-repeat saved-ans-repeat">
                                                                                                    <div
                                                                                                        className="likert-option-left">
                                                                                                        <div
                                                                                                            class="ans-data">{subQuestion}</div>
                                                                                                    </div>


                                                                                                    <div
                                                                                                        class="likert-option-right">
                                                                                                        <div
                                                                                                            class="survey-rating-options-wrap">
                                                                                                            <div
                                                                                                                class="bd-question-radio-data">
                                                                                                                <div
                                                                                                                    class="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                                    {items.options.map((option, index) => (
                                                                                                                        <label
                                                                                                                            for="radio4">
                                                                                                                            {/* <p>{option}</p> */}
                                                                                                                            <input
                                                                                                                                id={`radio-${index}`}
                                                                                                                                type="radio"
                                                                                                                                name="ans"
                                                                                                                                value={option}
                                                                                                                                class="hidden radio_btn_input"
                                                                                                                                required="required" />
                                                                                                                            <span>
                                                                                                                                <img
                                                                                                                                    className="empty-fill-icon"
                                                                                                                                    src={
                                                                                                                                        process.env.REACT_APP_URL +
                                                                                                                                        "images/Polygon-blank.svg"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                <img
                                                                                                                                    className="fill-icon"
                                                                                                                                    src={
                                                                                                                                        process.env.REACT_APP_URL +
                                                                                                                                        "images/pol-fill.svg"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </span>
                                                                                                                        </label>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {test.methodology == "Tree Test" && (
                                    <div className="target-group-data-repeat study-target-repeat">
                                        <div className="target-form-wrap w-100">
                                            <div className="row">
                                                <div className="col-md-5 col-lg-5">
                                                    <div className="study-review-left-info-wrap">
                                                        <p className="page-step-count">Page 3</p>
                                                        <h3 className="study-review-left-heading">
                                                            Tree testing
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-lg-7">
                                                    <div className={`study-review-right-info-wrap ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                                        {test.methodology === "Tree Test" &&
                                                            test.tasks &&
                                                            test.tasks.map((items, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="review-task-data-repeat tree-review-tasks">
                                                                            <h3>Task {index + 1}</h3>
                                                                            <p className="task-type-name">
                                                                                {items.title}
                                                                            </p>
                                                                            <p className="correct-answer-data mt-0">
                                                                                Correct answer:
                                                                            </p>
                                                                            <p className="selected-answer-tree">
                                                                                {items.correct_answer &&
                                                                                    items.correct_answer.map(
                                                                                        (answer, index, rows) => {
                                                                                            return (
                                                                                                <span>
                                                                                                    {index + 1 !== rows.length ? (
                                                                                                        answer + " > "
                                                                                                    ) : (
                                                                                                        <b className="pink-color">
                                                                                                            {answer}
                                                                                                        </b>
                                                                                                    )}
                                                                                                </span>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {test.methodology == "Card Sorting" && (
                                    <div className="target-group-data-repeat study-target-repeat">
                                        <div className="target-form-wrap w-100">
                                            <div className="row">
                                                <div className="col-md-5 col-lg-5">
                                                    <div className="study-review-left-info-wrap">
                                                        <p className="page-step-count">Page 3</p>
                                                        <h3 className="study-review-left-heading">
                                                            {test.card_sorting.card_sorting === "open" ? 'Open card sorting' : 'Closed card sorting'}
                                                        </h3>
                                                        <p className="preview-category-name-text">
                                                            {test.card_sorting.card_sorting === "open" &&
                                                                " Participants create and name their own categories"}
                                                            {test.card_sorting.card_sorting === "close" &&
                                                                " Categories are predetermined by researchers"}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-lg-7">
                                                    <div className={`study-review-right-info-wrap ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>

                                                        {test.card_sorting.card_sorting === "close" && (
                                                            <>
                                                                <h3 className="h3 font-inter">Categories</h3>
                                                                <div
                                                                    className="added-question-wrap card-selected-category-view">
                                                                    {test.card_sorting_card &&
                                                                        test.card_sorting_category.map(
                                                                            (items, index) => {
                                                                                return (
                                                                                    <div
                                                                                        key={index}
                                                                                        className="ans-list-repeat saved-ans-repeat flex-wrap"
                                                                                    >
                                                                                        <div className="ques-count">
                                                                                            {index + 1}
                                                                                        </div>
                                                                                        <div className="ans-data">
                                                                                            {items.name}
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                </div>
                                                            </>
                                                        )}

                                                        <h3 className="h3 font-inter mt-32">Cards</h3>

                                                        <div className="added-question-wrap cardsort-cards-view">
                                                            <div className="active-tiles-wrap">
                                                                {test.card_sorting_card &&
                                                                    test.card_sorting_card.map((items, index) => {
                                                                        return <span key={index}>{items.name}</span>;
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {test.methodology == "Preference Test" && (
                                    <div className="target-group-data-repeat study-target-repeat">
                                        <div className="target-form-wrap w-100">
                                            <div className="row">
                                                <div className="col-md-5 col-lg-5">
                                                    <div className="study-review-left-info-wrap">
                                                        <p className="page-step-count">Page 3</p>
                                                        <h3 className="study-review-left-heading preview-category-name-text-heading">
                                                            Preference test
                                                        </h3>
                                                        {test.preference_test_setting &&
                                                            (test.preference_test_setting.randomize_order ==
                                                                1 ||
                                                                test.preference_test_setting.show_fullscreen ==
                                                                1 ||
                                                                test.preference_test_setting.show_design_titles ==
                                                                1) && (
                                                                <ul className="preview-category-name-text">
                                                                    {test.preference_test_setting.randomize_order ==
                                                                        1 && (
                                                                            <li>
                                                                                Randomise the order that designs are shown
                                                                                to testers.
                                                                            </li>
                                                                        )}
                                                                    {test.preference_test_setting.show_fullscreen ==
                                                                        1 && <li>Show fullscreen view only</li>}
                                                                    {test.preference_test_setting.show_design_titles ==
                                                                        1 && <li>Show design titles to the participants</li>}
                                                                </ul>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-lg-7">
                                                    <div className={`study-review-right-info-wrap ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                                        <div className="task_title">{test.preference_test_setting.preference_test_title}</div>
                                                        {test.preference_test_designs && (
                                                            <div className="preference-designs-repeat">
                                                                {test.preference_test_designs.map(
                                                                    (item, index) => {
                                                                        return (
                                                                            <div className="preference-test-design-wrap">
                                                                                <div
                                                                                    className="prefer-design-image-view-hold">
                                                                                    <div
                                                                                        className="prefer-test-selected-design-view">
                                                                                        {item.type == "video" &&
                                                                                            <div class="video-design-icon">
                                                                                                <img
                                                                                                    src={process.env.REACT_APP_URL + "images/video-play-btn.svg"}
                                                                                                    alt="icon" />
                                                                                            </div>
                                                                                        }
                                                                                        <img
                                                                                            src={
                                                                                                process.env.REACT_APP_IMG_URL +
                                                                                                item.thumb
                                                                                            }
                                                                                        />

                                                                                        <div
                                                                                            className="prefer-test-preview-hover">
                                                                                            <div
                                                                                                className="prefer-design-action-option">
                                                                                                <span>
                                                                                                    <a
                                                                                                        href={
                                                                                                            process.env
                                                                                                                .REACT_APP_IMG_URL +
                                                                                                            item.design_file
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                process.env
                                                                                                                    .REACT_APP_URL +
                                                                                                                "images/eye.svg"
                                                                                                            }
                                                                                                            alt="icon"
                                                                                                        />
                                                                                                    </a>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <h3 className={`h3 prefer-design-name ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                                                                        {/*<span>
                                                                                        {index.toString().length == 1
                                                                                            ? "0" + (index + 1)
                                                                                            : index + 1}
                                                                                        </span>{" "}*/}
                                                                                        {item.title}
                                                                                    </h3>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {test.methodology == "Survey" && (
                                    <div className="target-group-data-repeat study-target-repeat">
                                        <div className="target-form-wrap w-100">
                                            <div className="row">
                                                <div className="col-md-5 col-lg-5">
                                                    <div className="study-review-left-info-wrap">
                                                        <p className="page-step-count">Page 2</p>
                                                        <h3 className="study-review-left-heading">
                                                            Survey questions
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-lg-7">
                                                    <div className={`study-review-right-info-wrap ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                                        <div className="added-question-wrap">
                                                            {surveyQuestion &&
                                                                surveyQuestion.map((items, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="ans-list-repeat saved-ans-repeat flex-wrap"
                                                                        >
                                                                            <div className="ques-count">
                                                                                {index + 1}
                                                                            </div>
                                                                            <div className="ans-data">
                                                                                <p className="small-info-heading">
                                                                                    {items.questionType === "singlechoice"
                                                                                        ? "Single line answer"
                                                                                        : items.questionType ===
                                                                                            "multiplechoice"
                                                                                            ? "Multi line answer"
                                                                                            : items.questionType ===
                                                                                                "ratingscale"
                                                                                                ? "Rating Scale"
                                                                                                : items.questionType ===
                                                                                                    "likertscale"
                                                                                                    ? "Likert Scale"
                                                                                                    : items.questionType ===
                                                                                                        "rankingscale"
                                                                                                        ? "Ranking Scale"
                                                                                                        : "Free text"}
                                                                                </p>
                                                                                {items.question}
                                                                            </div>

                                                                            <div className="answer-review-repeat w-100">
                                                                                {items.questionType != "likertscale" && items.options &&
                                                                                    items.options.map((option, i) => {
                                                                                        return (
                                                                                            <div
                                                                                                className={`review-answer-hold-count ${items.is_logic == 1 ? "add-logic-read-mode review-add-logic-read-mode" : ""}`}>
                                                                                                <span>
                                                                                                    <img
                                                                                                        src={
                                                                                                            process.env.REACT_APP_URL +
                                                                                                            "images/Polygon-bg.svg"
                                                                                                        }
                                                                                                    />
                                                                                                </span>
                                                                                                <p>{option}</p>
                                                                                                {items.is_logic == 1 &&
                                                                                                    <div
                                                                                                        className="ans-data jump-to-box review-jump-to-box">
                                                                                                        {
                                                                                                            items.jumpTo[i] == "End Survey" ?
                                                                                                                <>Jump to &nbsp;<b>End Survey</b></>
                                                                                                                :
                                                                                                                items.jumpTo[i] - (index + 1) == 1 ?
                                                                                                                    <>Jump to &nbsp;<b>Next Question</b></>
                                                                                                                    :
                                                                                                                    <>Jump to &nbsp;<b>Question {items.jumpTo[i]}</b></>
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        );
                                                                                    })}

                                                                                <div
                                                                                    className="likert-question-data-hold likert-view-wrap answer-review-repeat w-100 likert-review-wrap">

                                                                                    {items.questionType == "likertscale" && (
                                                                                        <div class="survey-rating-options-wrap">
                                                                                            <div class="bd-question-radio-data">
                                                                                                <div
                                                                                                    class="checkboxes radio_btn_class  d-flex rating-answer-options-radio mb-0 mt-0">

                                                                                                    <div
                                                                                                        class="ans-list-wrap likert-scale-option-add likert-scale-top-input-wrap">
                                                                                                        <div
                                                                                                            className="ans-list-repeat saved-ans-repeat">

                                                                                                            <div
                                                                                                                class="likert-option-left">
                                                                                                                <div
                                                                                                                    class="ans-icon">
                                                                                                                    &nbsp;
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="ans-data">
                                                                                                                    &nbsp;
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                class="likert-option-right">
                                                                                                                {items.options.map((option, index) => (
                                                                                                                    <label
                                                                                                                        for="radio4">
                                                                                                                        <p>{option}</p>
                                                                                                                    </label>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>


                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                    {items.questionType == "likertscale" && items.subQuestions.map(
                                                                                        (subQuestion, index) => (

                                                                                            <div
                                                                                                class="ans-list-wrap likert-scale-option-add">
                                                                                                <div
                                                                                                    class="ans-list-repeat saved-ans-repeat">
                                                                                                    <div
                                                                                                        class="likert-option-left">
                                                                                                        <div
                                                                                                            class="ans-data">{subQuestion}</div>
                                                                                                    </div>


                                                                                                    <div
                                                                                                        class="likert-option-right">
                                                                                                        <div
                                                                                                            class="survey-rating-options-wrap">
                                                                                                            <div
                                                                                                                class="bd-question-radio-data">
                                                                                                                <div
                                                                                                                    class="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                                    {items.options.map((option, index) => (
                                                                                                                        <label
                                                                                                                            for="radio4">
                                                                                                                            {/* <p>{option}</p> */}
                                                                                                                            <input
                                                                                                                                id={`radio-${index}`}
                                                                                                                                type="radio"
                                                                                                                                name="ans"
                                                                                                                                value={option}
                                                                                                                                class="hidden radio_btn_input"
                                                                                                                                required="required" />
                                                                                                                            <span>
                                                                                                                                <img
                                                                                                                                    className="empty-fill-icon"
                                                                                                                                    src={
                                                                                                                                        process.env.REACT_APP_URL +
                                                                                                                                        "images/Polygon-blank.svg"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                <img
                                                                                                                                    className="fill-icon"
                                                                                                                                    src={
                                                                                                                                        process.env.REACT_APP_URL +
                                                                                                                                        "images/pol-fill.svg"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </span>
                                                                                                                        </label>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {test.methodology == "Five Seconds Test" && (
                                    <div className="target-group-data-repeat study-target-repeat">
                                        <div className="target-form-wrap w-100">
                                            <div className="row">
                                                <div className="col-md-5 col-lg-5">
                                                    <div className="study-review-left-info-wrap">
                                                        <p className="page-step-count">Page 3</p>
                                                        <h3 className="study-review-left-heading preview-category-name-text-heading">
                                                            Five seconds test
                                                        </h3>

                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-lg-7">
                                                    <div className={`study-review-right-info-wrap ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                                        <div className="five_seconds_review_heading">Task</div>
                                                        <h3 className="five_seconds_review_task">{test.fiveSecondsTestTask.task}</h3>
                                                        <div className="five_seconds_review_heading">Design</div>
                                                        {test.fiveSecondsTestTask.task_file && (
                                                            <div className="preference-designs-repeat five_seconds_review_design">
                                                                <div className="preference-test-design-wrap">
                                                                    <div
                                                                        className="prefer-design-image-view-hold">
                                                                        <div
                                                                            className="prefer-test-selected-design-view">
                                                                            {test.fiveSecondsTestTask.file_type == "video" &&
                                                                                <div class="video-design-icon">
                                                                                    <img
                                                                                        src={process.env.REACT_APP_URL + "images/video-play-btn.svg"}
                                                                                        alt="icon" />
                                                                                </div>
                                                                            }
                                                                            {test.fiveSecondsTestTask.file_type == "video"
                                                                                ?
                                                                                <video
                                                                                    src={
                                                                                        process.env.REACT_APP_IMG_URL +
                                                                                        test.fiveSecondsTestTask.task_file
                                                                                    }
                                                                                    alt="video"
                                                                                    width="100%"
                                                                                />
                                                                                :
                                                                                <img
                                                                                    src={
                                                                                        process.env.REACT_APP_IMG_URL +
                                                                                        test.fiveSecondsTestTask.task_file
                                                                                    }
                                                                                />
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {test.methodology == "Five Seconds Test" && (
                                    <div className="target-group-data-repeat study-target-repeat">
                                        <div className="target-form-wrap w-100">
                                            <div className="row">
                                                <div className="col-md-5 col-lg-5">
                                                    <div className="study-review-left-info-wrap">
                                                        <p className="page-step-count">Page 4</p>
                                                        <h3 className="study-review-left-heading">
                                                            Following questions
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-lg-7">
                                                    <div className={`study-review-right-info-wrap ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                                        <div className="added-question-wrap">
                                                            {fiveSecondsQuestion &&
                                                                fiveSecondsQuestion.map((items, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="ans-list-repeat saved-ans-repeat flex-wrap"
                                                                        >
                                                                            <div className="ques-count">
                                                                                {index + 1}
                                                                            </div>
                                                                            <div className="ans-data">
                                                                                <p className="small-info-heading">
                                                                                    {items.questionType === "singlechoice"
                                                                                        ? "Single line answer"
                                                                                        : items.questionType ===
                                                                                            "multiplechoice"
                                                                                            ? "Multi line answer"
                                                                                            : items.questionType ===
                                                                                                "ratingscale"
                                                                                                ? "Rating Scale"
                                                                                                : items.questionType ===
                                                                                                    "likertscale"
                                                                                                    ? "Likert Scale"
                                                                                                    : items.questionType ===
                                                                                                        "rankingscale"
                                                                                                        ? "Ranking Scale"
                                                                                                        : "Free text"}
                                                                                </p>
                                                                                {items.question}
                                                                            </div>

                                                                            <div className="answer-review-repeat w-100">
                                                                                {items.questionType != "likertscale" && items.options &&
                                                                                    items.options.map((option, i) => {
                                                                                        return (
                                                                                            <div
                                                                                                className={`review-answer-hold-count ${items.is_logic == 1 ? "add-logic-read-mode review-add-logic-read-mode" : ""}`}>
                                                                                                <span>
                                                                                                    <img
                                                                                                        src={
                                                                                                            process.env.REACT_APP_URL +
                                                                                                            "images/Polygon-bg.svg"
                                                                                                        }
                                                                                                    />
                                                                                                </span>
                                                                                                <p>{option}</p>
                                                                                                {items.is_logic == 1 &&
                                                                                                    <div
                                                                                                        className="ans-data jump-to-box review-jump-to-box">
                                                                                                        {
                                                                                                            items.jumpTo[i] == "End Survey" ?
                                                                                                                <>Jump to &nbsp;<b>End Survey</b></>
                                                                                                                :
                                                                                                                items.jumpTo[i] - (index + 1) == 1 ?
                                                                                                                    <>Jump to &nbsp;<b>Next Question</b></>
                                                                                                                    :
                                                                                                                    <>Jump to &nbsp;<b>Question {items.jumpTo[i]}</b></>
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        );
                                                                                    })}

                                                                                <div
                                                                                    className="likert-question-data-hold likert-view-wrap answer-review-repeat w-100 likert-review-wrap">

                                                                                    {items.questionType == "likertscale" && (
                                                                                        <div class="survey-rating-options-wrap">
                                                                                            <div class="bd-question-radio-data">
                                                                                                <div
                                                                                                    class="checkboxes radio_btn_class  d-flex rating-answer-options-radio mb-0 mt-0">

                                                                                                    <div
                                                                                                        class="ans-list-wrap likert-scale-option-add likert-scale-top-input-wrap">
                                                                                                        <div
                                                                                                            className="ans-list-repeat saved-ans-repeat">

                                                                                                            <div
                                                                                                                class="likert-option-left">
                                                                                                                <div
                                                                                                                    class="ans-icon">
                                                                                                                    &nbsp;
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="ans-data">
                                                                                                                    &nbsp;
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                class="likert-option-right">
                                                                                                                {items.options.map((option, index) => (
                                                                                                                    <label
                                                                                                                        for="radio4">
                                                                                                                        <p>{option}</p>
                                                                                                                    </label>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>


                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                    {items.questionType == "likertscale" && items.subQuestions.map(
                                                                                        (subQuestion, index) => (

                                                                                            <div
                                                                                                class="ans-list-wrap likert-scale-option-add">
                                                                                                <div
                                                                                                    class="ans-list-repeat saved-ans-repeat">
                                                                                                    <div
                                                                                                        class="likert-option-left">
                                                                                                        <div
                                                                                                            class="ans-data">{subQuestion}</div>
                                                                                                    </div>


                                                                                                    <div
                                                                                                        class="likert-option-right">
                                                                                                        <div
                                                                                                            class="survey-rating-options-wrap">
                                                                                                            <div
                                                                                                                class="bd-question-radio-data">
                                                                                                                <div
                                                                                                                    class="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                                    {items.options.map((option, index) => (
                                                                                                                        <label
                                                                                                                            for="radio4">
                                                                                                                            {/* <p>{option}</p> */}
                                                                                                                            <input
                                                                                                                                id={`radio-${index}`}
                                                                                                                                type="radio"
                                                                                                                                name="ans"
                                                                                                                                value={option}
                                                                                                                                class="hidden radio_btn_input"
                                                                                                                                required="required" />
                                                                                                                            <span>
                                                                                                                                <img
                                                                                                                                    className="empty-fill-icon"
                                                                                                                                    src={
                                                                                                                                        process.env.REACT_APP_URL +
                                                                                                                                        "images/Polygon-blank.svg"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                <img
                                                                                                                                    className="fill-icon"
                                                                                                                                    src={
                                                                                                                                        process.env.REACT_APP_URL +
                                                                                                                                        "images/pol-fill.svg"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </span>
                                                                                                                        </label>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(test.methodology != "Survey" && test.methodology != "Five Seconds Test") && (
                                    <div className="target-group-data-repeat study-target-repeat">
                                        <div className="target-form-wrap w-100">
                                            <div className="row">
                                                <div className="col-md-5 col-lg-5">
                                                    <div className="study-review-left-info-wrap">
                                                        <p className="page-step-count">Page 4</p>
                                                        <h3 className="study-review-left-heading">
                                                            Debriefing questions
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-lg-7">
                                                    <div className={`study-review-right-info-wrap ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                                        <div className="added-question-wrap">
                                                            {deBriefingQuestion.length === 0 ?
                                                                <p className={"info-not-available big-font"}>You have not
                                                                    added any debriefing questions to your test </p>
                                                                : ''}
                                                            {deBriefingQuestion &&
                                                                deBriefingQuestion.map((items, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="ans-list-repeat saved-ans-repeat flex-wrap"
                                                                        >
                                                                            <div className="ques-count">
                                                                                {index + 1}
                                                                            </div>
                                                                            <div className="ans-data">
                                                                                <p className="small-info-heading">
                                                                                    {items.questionType === "singlechoice"
                                                                                        ? "Single choice question"
                                                                                        : items.questionType === "multiplechoice"
                                                                                            ? "Multi choice question"
                                                                                            : items.questionType === "likertscale"
                                                                                                ? "Likert scale"
                                                                                                : "Free text"}
                                                                                </p>
                                                                                {items.question}
                                                                            </div>

                                                                            <div className="answer-review-repeat w-100">
                                                                                {items.questionType != "likertscale" && items.options &&
                                                                                    items.options.map((option) => {
                                                                                        return (
                                                                                            <div
                                                                                                className="review-answer-hold-count">
                                                                                                <span>
                                                                                                    <img
                                                                                                        src={
                                                                                                            process.env.REACT_APP_URL +
                                                                                                            "images/Polygon-bg.svg"
                                                                                                        }
                                                                                                    />
                                                                                                </span>
                                                                                                <p>{option}</p>
                                                                                            </div>
                                                                                        );
                                                                                    })}

                                                                                <div
                                                                                    className="likert-question-data-hold likert-view-wrap answer-review-repeat w-100 likert-review-wrap">

                                                                                    {items.questionType == "likertscale" && (
                                                                                        <div class="survey-rating-options-wrap">
                                                                                            <div class="bd-question-radio-data">
                                                                                                <div
                                                                                                    class="checkboxes radio_btn_class  d-flex rating-answer-options-radio mb-0 mt-0">

                                                                                                    <div
                                                                                                        class="ans-list-wrap likert-scale-option-add likert-scale-top-input-wrap">
                                                                                                        <div
                                                                                                            className="ans-list-repeat saved-ans-repeat">

                                                                                                            <div
                                                                                                                class="likert-option-left">
                                                                                                                <div
                                                                                                                    class="ans-icon">
                                                                                                                    &nbsp;
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="ans-data">
                                                                                                                    &nbsp;
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                class="likert-option-right">
                                                                                                                {items.options.map((option, index) => (
                                                                                                                    <label
                                                                                                                        for="radio4">
                                                                                                                        <p>{option}</p>
                                                                                                                    </label>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                    {items.questionType == "likertscale" && items.subQuestions.map(
                                                                                        (subQuestion, index) => (

                                                                                            <div
                                                                                                class="ans-list-wrap likert-scale-option-add">
                                                                                                <div
                                                                                                    class="ans-list-repeat saved-ans-repeat">
                                                                                                    <div
                                                                                                        class="likert-option-left">
                                                                                                        <div
                                                                                                            class="ans-data">{subQuestion}</div>
                                                                                                    </div>


                                                                                                    <div
                                                                                                        class="likert-option-right">
                                                                                                        <div
                                                                                                            class="survey-rating-options-wrap">
                                                                                                            <div
                                                                                                                class="bd-question-radio-data">
                                                                                                                <div
                                                                                                                    class="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                                    {items.options.map((option, index) => (
                                                                                                                        <label
                                                                                                                            for="radio4">
                                                                                                                            {/* <p>{option}</p> */}
                                                                                                                            <input
                                                                                                                                id={`radio-${index}`}
                                                                                                                                type="radio"
                                                                                                                                name="ans"
                                                                                                                                value={option}
                                                                                                                                class="hidden radio_btn_input"
                                                                                                                                required="required" />
                                                                                                                            <span>
                                                                                                                                <img
                                                                                                                                    className="empty-fill-icon"
                                                                                                                                    src={
                                                                                                                                        process.env.REACT_APP_URL +
                                                                                                                                        "images/Polygon-blank.svg"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                <img
                                                                                                                                    className="fill-icon"
                                                                                                                                    src={
                                                                                                                                        process.env.REACT_APP_URL +
                                                                                                                                        "images/pol-fill.svg"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </span>
                                                                                                                        </label>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="target-group-data-repeat study-target-repeat">
                                    <div className="target-form-wrap w-100">
                                        <div className="row">
                                            <div className="col-md-5 col-lg-5">
                                                <div className="study-review-left-info-wrap">
                                                    {test.methodology != "Survey" ? (
                                                        <p className="page-step-count">Page 5</p>
                                                    ) : (
                                                        <p className="page-step-count">Page 3</p>
                                                    )}
                                                    <h3 className="study-review-left-heading">
                                                        Thank you page
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="col-md-7 col-lg-7">
                                                <div className={`study-review-right-info-wrap ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="uploaded-project-img study-review-image-wrap">
                                                                <img
                                                                    src={
                                                                        test.image
                                                                            ? process.env.REACT_APP_IMG_URL +
                                                                            test.conclusion_image
                                                                            : process.env.REACT_APP_URL +
                                                                            "images/thanku-placeholder.png"
                                                                    }
                                                                    alt="img"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="study-review-welcome-text">
                                                                <h3 className="h3">
                                                                    {test.thankyou_title}
                                                                </h3>
                                                                <p className="review-study-small-text">
                                                                    {test.thankyou_description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="target-group-data-repeat study-target-repeat border-0">*/}
                                {/*    <div className="target-form-wrap w-100">*/}
                                {/*        <div className="row">*/}
                                {/*            <div className="col-md-5 col-lg-5">*/}
                                {/*                <div className="study-review-left-info-wrap">*/}
                                {/*                    <h3 className="study-review-left-heading">*/}
                                {/*                        Your participants*/}
                                {/*                    </h3>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}

                                {/*            <div className="col-md-7 col-lg-7">*/}
                                {/*                <div className="study-review-right-info-wrap">*/}


                                {/*                    {test.target_group && test.target_group.participants_selected &&*/}
                                {/*                        <div className="row">*/}
                                {/*                            {test.target_group.project_target_group &&*/}
                                {/*                                <div className={`col-md-6`}>*/}
                                {/*                                    <div*/}
                                {/*                                        className="uploaded-project-img study-review-image-wrap tester-target-review">*/}
                                {/*                                        <p>Target group selected</p>*/}
                                {/*                                        <h3 className="target-groupname-review">{test.target_group.project_target_group && test.target_group.project_target_group.target_group_name}</h3>*/}
                                {/*                                    </div>*/}
                                {/*                                </div>*/}
                                {/*                            }*/}
                                {/*                            <div className="col-md-6">*/}
                                {/*                                <div*/}
                                {/*                                    className="uploaded-project-img study-review-image-wrap tester-target-review">*/}

                                {/*                                    <p>{test.target_group.project_target_group ? "Participants selected from this panel" : "Random participants from UserQ Panel"}</p>*/}
                                {/*                                    <h2 className="selected-participants-review">*/}
                                {/*                                        {test.target_group &&*/}
                                {/*                                            test.target_group.participants_selected}*/}
                                {/*                                    </h2>*/}
                                {/*                                </div>*/}
                                {/*                            </div>*/}
                                {/*                        </div>*/}
                                {/*                    }*/}
                                {/*                    {!(test.target_group && test.target_group.participants_selected) &&*/}
                                {/*                        <div className="row">*/}
                                {/*                            <div className={`col-md-12`}>*/}
                                {/*                                <div className="uploaded-project-img study-review-image-wrap">*/}
                                {/*                                    <p>You've chosen to not use UserQ's tester panel – but*/}
                                {/*                                        you'll be able to share the test link with your own*/}
                                {/*                                        group of participants once you hit publish.</p>*/}
                                {/*                                </div>*/}
                                {/*                            </div>*/}

                                {/*                        </div>*/}
                                {/*                    }*/}
                                {/*                </div>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*
                            <div className="target-group-data-repeat study-target-repeat pb-0 border-0">
                                <div className="target-form-wrap w-100">
                                    <div className="selected-plan-wrapper mb-0 study-review-credit-plan-wrap mt-0">
                                        <div className="selected-plan-inner-data">
                                            <div className="selected-plan-top">
                                                <div className="sel-pln-tp-left">
                                                    <p>
                                                        <span>Your testers</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="selected-plan-mid-wrap">
                                                    {test.target_group &&
                                                        test.target_group.target_type === "target-group" && (
                                                        <>
                                                            <div className="selected-plan-info-repeat plan-first-repeat">
                                                                <p>{test.target_group.project_target_group && test.target_group.project_target_group.target_group_name}</p>
                                                                <div className="target-tester-and-speaker-selected">
                                                                    <div className="targ-ts-count">
                                                                        <h2>{test.testers_count}</h2>
                                                                        <span>testers</span>
                                                                    </div>

                                                                    <div className="targ-ts-count">
                                                                        <h2>{test.language_speakers_count}</h2>
                                                                        <span>
                                                                            {" "}
                                                                            {test.language === "en"
                                                                                ? "English"
                                                                                : "Arabic"}{" "}
                                                                            speakers
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    {test.target_group &&
                                                    test.target_group.participants_selected && (
                                                        <div
                                                            className={` ${test.target_group &&
                                                                    test.target_group.target_type !== "target-group"
                                                                    ? "w-50 "
                                                                    : ""
                                                                } selected-plan-info-repeat`}
                                                        >
                                                            <p>Testers selected from panel</p>
                                                            <h2>
                                                                {test.target_group &&
                                                                    test.target_group.participants_selected}
                                                            </h2>
                                                        </div>
                                                    )}

                                                <div
                                                    className={`${test.target_group && test.target_group.credits
                                                            ? test.target_group &&
                                                                test.target_group.target_type ===
                                                                "random-testers"
                                                                ? "w-50 "
                                                                : ""
                                                            : "w-100 "
                                                        } selected-plan-info-repeat`}
                                                >
                                                    {test.target_group && test. target_group.credits ? (
                                                        <>
                                                            <p>Total cost</p>
                                                            <h2>{test.target_group.credits} Credits</h2>
                                                        </>
                                                    ) : (
                                                        <p>
                                                            You've chosen to not use UserQ's tester panel –
                                                            but you'll be able to share the test link with
                                                            your own group of testers once you hit publish.
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                                <div className="target-group-data-repeat study-target-repeat pt-0 pb-0">
                                    <div className="border-0 pt-0 w-100">
                                        <div className="button-wrap d-flex justify-content-end mt-64">
                                            <button
                                                id="r_create_step_5_review_next_btn"
                                                type="submit"
                                                onClick={() => handleSubmit(true)}
                                                className="button primary-btn"
                                            >
                                                Next
                                                {reviewStepLoading && (
                                                    <i
                                                        className="fa fa-spinner fa-spin"
                                                        aria-hidden="true"
                                                        style={{ marginLeft: "5px" }}
                                                    ></i>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </LayoutResearcher>
    );
}
